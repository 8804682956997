import React from 'react';
import PropTypes from 'prop-types';

import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

import CTA from '../../components/ctaContainer';
import Hero from '../../components/hedis/hedisHero';
import Layout from '../../components/layout';
import Quality from '../../components/hedis/hedisQuality';
import HedisStatements from '../../components/hedis/hedisStatements';

const pageDescription =
  'Fewer headaches, fewer open gaps. Prioritizing members and measures can be complex, but it doesn’t have to be.';

/**
 * Hedis page component. Parent component.
 * @component
 *
 * @param   {string} location - URL data object
 * @returns {component}       - <Hedis location={string} />
 */

const Hedis = ({ location }) => (
  <Layout
    pageTitle="HEDIS &amp; Adherence"
    pageDescription={pageDescription}
    location={location}
  >
    <Hero />
    <HedisStatements />
    <Quality />
    <CTA
      background={main.colorGradientBlue}
      button={theme.dpBtnBlue}
      color={theme.colorBlue}
      solution="profile"
    />
  </Layout>
);

Hedis.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default Hedis;
