import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

import CleanHTML from '../cleanHTML';

/**
 * HEDIS page Quality component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <Quality />
 */

const Quality = () => {
  const {
    contentfulAsset,
    contentfulHedis: {
      firstQualityCopy,
      firstQualityNumber,
      fourthQualityCopy,
      fourthQualityNumber,
      qualityTitle,
      secondQualityCopy,
      secondQualityNumber,
      thirdQualityCopy,
      thirdQualityNumber,
    },
  } = useStaticQuery(graphql`
    query hedisQuality {
      contentfulAsset(title: { eq: "increase" }) {
        file {
          url
        }
      }
      contentfulHedis {
        firstQualityCopy
        firstQualityNumber
        fourthQualityCopy
        fourthQualityNumber
        qualityTitle
        secondQualityCopy
        secondQualityNumber
        thirdQualityCopy
        thirdQualityNumber
      }
    }
  `);

  return (
    <section
      className={cx(
        base.flex,
        base.itemsCenter,
        base.justifyCenter,
        base.w100,
        main.hedisQuality,
        main.px60
      )}
    >
      <div
        className={cx(
          base.flex,
          base.flexColumn,
          base.itemsCenter,
          base.justifyBetween,
          base.w100,
          main.hedisQualityInner
        )}
      >
        <h2
          className={cx(
            base.mb4,
            base.pb2,
            main.innerCopyBlue,
            main.subHeading,
            theme.colorDark
          )}
        >
          <CleanHTML html={qualityTitle} />
        </h2>
        <ul
          className={cx(
            base.flex,
            base.flexWrap,
            base.itemCenter,
            base.justifyCenter,
            base.ma0,
            base.pa0,
            base.w100,
            main.hedisQualityList
          )}
        >
          <li
            className={cx(
              base.flex,
              base.flexColumn,
              base.itemsStart,
              base.justifyStart,
              base.ma3,
              main.hedisQualityItem,
              theme.shadow,
              theme.shapeWhite
            )}
          >
            <div
              className={cx(
                base.flex,
                base.itemsCenter,
                base.justifyCenter,
                base.w100,
                main.hedisQualityItemTop
              )}
            >
              <p className={cx(base.ma0, theme.colorDark, theme.title0)}>
                {firstQualityNumber}
              </p>
              <img
                src={contentfulAsset.file.url}
                alt=""
                aria-hidden="true"
                className={main.hedisQualityIcon}
              />
            </div>
            <div
              className={cx(
                base.flex,
                base.itemsCenter,
                base.justifyCenter,
                base.w100,
                main.hedisQualityItemBottom
              )}
            >
              <p
                className={cx(
                  base.ma0,
                  base.tc,
                  main.stCopy,
                  theme.colorTextGrey
                )}
              >
                {firstQualityCopy}
              </p>
            </div>
          </li>
          <li
            className={cx(
              base.flex,
              base.flexColumn,
              base.itemsStart,
              base.justifyStart,
              base.ma3,
              main.hedisQualityItem,
              theme.shadow,
              theme.shapeWhite
            )}
          >
            <div
              className={cx(
                base.flex,
                base.itemsCenter,
                base.justifyCenter,
                base.w100,
                main.hedisQualityItemTop
              )}
            >
              <p className={cx(base.ma0, theme.colorDark, theme.title0)}>
                {secondQualityNumber}
              </p>
              <img
                src={contentfulAsset.file.url}
                alt=""
                aria-hidden="true"
                className={main.hedisQualityIcon}
              />
            </div>
            <div
              className={cx(
                base.flex,
                base.itemsCenter,
                base.justifyCenter,
                base.w100,
                main.hedisQualityItemBottom
              )}
            >
              <p
                className={cx(
                  base.ma0,
                  base.tc,
                  main.stCopy,
                  theme.colorTextGrey
                )}
              >
                {secondQualityCopy}
              </p>
            </div>
          </li>
          <li
            className={cx(
              base.flex,
              base.flexColumn,
              base.itemsStart,
              base.justifyStart,
              base.ma3,
              main.hedisQualityItem,
              theme.shadow,
              theme.shapeWhite
            )}
          >
            <div
              className={cx(
                base.flex,
                base.itemsCenter,
                base.justifyCenter,
                base.w100,
                main.hedisQualityItemTop
              )}
            >
              <p className={cx(base.ma0, theme.colorDark, theme.title0)}>
                {thirdQualityNumber}
              </p>
              <img
                src={contentfulAsset.file.url}
                alt=""
                aria-hidden="true"
                className={main.hedisQualityIcon}
              />
            </div>
            <div
              className={cx(
                base.flex,
                base.itemsCenter,
                base.justifyCenter,
                base.w100,
                main.hedisQualityItemBottom
              )}
            >
              <p
                className={cx(
                  base.ma0,
                  base.tc,
                  main.stCopy,
                  theme.colorTextGrey
                )}
              >
                {thirdQualityCopy}
              </p>
            </div>
          </li>
          <li
            className={cx(
              base.flex,
              base.flexColumn,
              base.itemsStart,
              base.justifyStart,
              base.ma3,
              main.hedisQualityItem,
              theme.shadow,
              theme.shapeWhite
            )}
          >
            <div
              className={cx(
                base.flex,
                base.itemsCenter,
                base.justifyCenter,
                base.w100,
                main.hedisQualityItemTop
              )}
            >
              <p className={cx(base.ma0, theme.colorDark, theme.title0)}>
                {fourthQualityNumber}
              </p>
              <img
                src={contentfulAsset.file.url}
                alt=""
                aria-hidden="true"
                className={main.hedisQualityIcon}
              />
            </div>
            <div
              className={cx(
                base.flex,
                base.itemsCenter,
                base.justifyCenter,
                base.w100,
                main.hedisQualityItemBottom
              )}
            >
              <p
                className={cx(
                  base.ma0,
                  base.tc,
                  main.stCopy,
                  theme.colorTextGrey
                )}
              >
                {fourthQualityCopy}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Quality;
