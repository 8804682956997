import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Statements from '../statements';

/**
 * HEDIS page Statements component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <HedisStatements />
 */

const HedisStatements = () => {
  const {
    contentfulHedis: {
      engagementAlt,
      engagementContent,
      engagementFlipped,
      engagementImage,
      engagementTitle,
      returnAlt,
      returnContent,
      returnFlipped,
      returnImage,
      returnTitle,
      tailoredAlt,
      tailoredContent,
      tailoredFlipped,
      tailoredImage,
      tailoredTitle,
    },
  } = useStaticQuery(graphql`
    query hedisStatements {
      contentfulHedis {
        engagementAlt
        engagementContent {
          childMarkdownRemark {
            html
          }
        }
        engagementFlipped
        engagementImage {
          fluid(maxWidth: 450, quality: 80, resizingBehavior: FILL) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        engagementTitle
        returnAlt
        returnContent {
          childMarkdownRemark {
            html
          }
        }
        returnFlipped
        returnImage {
          fluid(maxWidth: 450, quality: 80, resizingBehavior: FILL) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        returnTitle
        tailoredAlt
        tailoredContent {
          childMarkdownRemark {
            html
          }
        }
        tailoredFlipped
        tailoredImage {
          fluid(maxWidth: 450, quality: 80, resizingBehavior: FILL) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        tailoredTitle
      }
    }
  `);

  const statements = [
    {
      alt: engagementAlt,
      copy: engagementContent.childMarkdownRemark.html,
      flipped: engagementFlipped,
      heading: engagementTitle,
      image: engagementImage.fluid,
    },
    {
      alt: tailoredAlt,
      copy: tailoredContent.childMarkdownRemark.html,
      flipped: tailoredFlipped,
      heading: tailoredTitle,
      image: tailoredImage.fluid,
    },
    {
      alt: returnAlt,
      copy: returnContent.childMarkdownRemark.html,
      flipped: returnFlipped,
      heading: returnTitle,
      image: returnImage.fluid,
    },
  ];

  return <Statements sections={statements} />;
};

export default HedisStatements;
